import { FC, memo } from 'react';

import { YTEmbeddedVideo } from 'src/common';

import { Container, Title, YTWrapper } from './EmbeddedVideo.styles';

export type EmbeddedVideoProps = {
  title?: string;
  ytVideoId: string;
};

const EmbeddedVideo: FC<EmbeddedVideoProps> = ({ title, ytVideoId }) => {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <YTWrapper>
        <YTEmbeddedVideo
          title={title || 'Watch video.'}
          ytVideoId={ytVideoId}
          coverWidth={1920}
          coverHeight={1080}
        />
      </YTWrapper>
    </Container>
  );
};

export default memo(EmbeddedVideo);
