import styled from 'styled-components';
import { device } from 'constants/device';

export const Container = styled.section<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.system.white};
`;

export const Content = styled.div`
  position: relative;
  padding: 0 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  min-height: 464px;

  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const Wrapper = styled.div`
  padding: 66px 0 124px;

  @media ${device.tablet} {
    padding: 40px 0 36px;
  }
`;

export const H2 = styled.h2`
  font-size: 3.2rem;
  line-height: 4.1rem;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;

  color: ${({ theme }) => theme.brand.blue};

  @media ${device.mobileL} {
    margin-bottom: 16px;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;
  }
`;

export const FAQList = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  & > * {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`;
