import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  border-radius: 8px;
`;

export const Header = styled.div`
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  min-height: 72px;

  &:hover {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div<{ isExpanded?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    color: ${({ theme }) => theme.brand.blue};
    transform: rotate(${({ isExpanded }) => (isExpanded ? '180deg' : '0deg')});
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.brand.blue};
`;

export const HeaderText = styled.div`
  margin: 26px 0;
  flex: 1;

  ${Text} {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
  }
`;

export const Content = styled.div``;

export const TextContentWrapper = styled.div`
  padding: 0 24px 24px;

  ${Text} {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
  }
`;
