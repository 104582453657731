import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const Button = styled.a`
  width: fit-content;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.business.white};
  position: relative;
  display: flex;
  padding: 10px 32px;
  background-color: #1dbf70;
  border-radius: 8px;
  transition: all 0.1s ease-in;
  align-items: center;

  &:hover {
    background-color: #20d47c;
    color: #ffffff;
  }

  ${down('md')} {
    width: 100%;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  background: #083344;
  position: relative;
`;

export const TextContent = styled.div`
  display: flex;
  max-width: 744px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.span`
  color: #ffffff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;

  ${down('md')} {
    font-size: 28px;
    font-weight: 700;
    line-height: 130%;
  }
`;

export const Description = styled.span`
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  opacity: 0.699999988079071;

  ${down('md')} {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
`;

export const Blur = styled.div`
  width: 512px;
  height: 512px;
  position: absolute;
  top: -135px;
  left: calc(50% - 256px);
  display: block;
  border-radius: 512px;
  opacity: 0.15000000596046448;
  background: #fff;
  filter: blur(150px);
  overflow-x: hidden;
  max-width: 100vw;
`;
