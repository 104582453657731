import { FC, memo } from 'react';
import Link from 'next/link';
import Lottie from 'lottie-react';

import { LinkComponent } from 'api_entities/strapi/types';

import {
  Container,
  Inner,
  Content,
  Title,
  Text,
  TextWrapper,
  StyledLink,
  LottieWrapper,
} from './Banner.styles';

export type BannerProps = {
  title: string;
  description: string;
  lottie?: Record<string, any>;
  link?: LinkComponent;
};

const Banner: FC<BannerProps> = ({ title, description, link, lottie }) => {
  return (
    <Container hideBackgroundImg={!!lottie}>
      <Inner>
        <Content>
          <TextWrapper>
            <Title>{title}</Title>
            <Text>{description}</Text>
            {link ? (
              <Link passHref href={link.href}>
                <StyledLink
                  rel={link.isExternal ? 'noopener' : undefined}
                  target={link.openNewTab ? '_blank' : undefined}
                >
                  {link.label}
                </StyledLink>
              </Link>
            ) : null}
          </TextWrapper>
          {lottie ? (
            <LottieWrapper>
              <Lottie loop animationData={lottie} />
            </LottieWrapper>
          ) : null}
        </Content>
      </Inner>
    </Container>
  );
};

export default memo(Banner);
