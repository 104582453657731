import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  width: 100%;
  margin: 16px auto;

  ${up('md')} {
    margin: 24px auto;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.3;

  margin-bottom: 12px;
`;

export const YTWrapper = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  border-radius: 9px;
  overflow: hidden;

  & img {
    object-fit: cover;
    aspect-ratio: 16 / 9;
    display: block;
    width: 100%;
    height: auto;
  }

  & iframe {
    width: 100%;
    height: 100%;
  }
`;
