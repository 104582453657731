import { VacancyAdCard } from '../vacancies/types';

export enum BODY_COMPONENTS {
  RICH_TEXT = 'common.rich-text-block',
  FAQ = 'body.fa-q',
  USEFULL_LINKS = 'body.usefull-links',
  INTERNAL_LINKS = 'body.internal-links-block',
  BANNER = 'body.banner',
  EMBEDDED_IMAGE = 'common.embedded-image',
  HIGHLIGHTED_LINK = 'body.highlighted-link',
  EMBEDDED_YOUTUBE = 'body.embedded-you-tube',
}

export type PageBody = Array<
  | ({ id: number; __component: BODY_COMPONENTS.FAQ } & FaqComponent)
  | ({ id: number; __component: BODY_COMPONENTS.BANNER } & BannerComponent)
  | ({ id: number; __component: BODY_COMPONENTS.USEFULL_LINKS } & UseFullLinksComponent)
  | ({ id: number; __component: BODY_COMPONENTS.INTERNAL_LINKS } & InternalLinksComponent)
  | ({ id: number; __component: BODY_COMPONENTS.RICH_TEXT } & RichTextCommonComponent)
  | ({ id: number; __component: BODY_COMPONENTS.EMBEDDED_IMAGE } & EmbeddedImageComponent)
  | ({ id: number; __component: BODY_COMPONENTS.HIGHLIGHTED_LINK } & HighlightedLinkComponent)
  | ({ id: number; __component: BODY_COMPONENTS.EMBEDDED_YOUTUBE } & EmbeddedYouTube)
>;

export type HomePageContent = {
  id: number;
  attributes: {
    seoMeta?: SeoMetaComponent;
    jobSearchPages?: JobSearchComponent[];
    trendJobs?: TrendJobsComponent;
    videoCta?: VideoCTAComponent;
    faq?: FaqComponent;
    studentsTools?: StudentsToolsComponent;
    footerCta?: FooterCTAComponent;

    createdAt: string;
    updatedAt: string;
  };
};

export type WerkgeverPageContent = {
  id: number;
  attributes: {
    seoMeta?: SeoMetaComponent;
    titleHero: string;
    textHero: string;
    partners?: PartnersComponent;
    services?: ServicesComponent;
    quote?: QuoteComponent;
    explore?: JobSearchComponent[];
    textContent?: RichTextCommonComponent;
    faq?: FaqComponent;
    testimonials?: TestimonialsComponent;
    createdAt: string;
    updatedAt: string;
  };
};

export type AboutUsContent = {
  id: number;
  attributes: {
    heading?: string;
    content?: AboutUsSection[];
    seoMeta?: SeoMetaComponent;
    createdAt: string;
    updatedAt: string;
  };
};

export type HeaderMenu = {
  id: number;
  attributes: {
    menu: Array<{
      id: number;
      label: string;
      href?: string;
      templateType?: string;
      items: HeaderMenuItem[];
    }>;
    submenu: Array<{
      id: number;
      label: string;
      key: string;
      items: HeaderSubmenuItem[];
    }>;

    createdAt: string;
    updatedAt: string;
  };
};

export type HeaderMenuItem = {
  id: number;
  label: string;
  href?: string;
  subMenuKey?: string;
};

export type HeaderSubmenuItem = {
  id: number;
  label: string;
  href: string;
};

export type PillarPage = {
  id: number;
  attributes: {
    name: string;
    createdAt: string;
    updatedAt: string;
    publishedAt?: string;
    slug: string;
    mainHeader?: string;
    hero?: HeroComponent;
    faq?: FaqComponent;
    testimonials?: TestimonialsComponent;
    services?: ServicesComponent;
    seoMeta?: SeoMetaComponent;
    body: PageBody;
    asideTopBaner?: AsideBannerComponent;
    asideBottomBaner?: AsideBannerComponent;
    author: { data?: Author };
    summary?: SummaryItem[];
    templateType?: string;
    category: string;
    filter: string;
    studentsListTitle?: string;
    vacanciesAdsSection: VacanciesAdSection;
  };
};

export type BlogArticle = {
  id: number;
  attributes: {
    title: string;
    dateOfPublication: string;
    slug: string;
    readTime: number;
    body: PageBody;
    seoMeta?: SeoMetaComponent;
    author: { data?: Author };
    cover: { data?: MediaComponent };
    category: { data?: ArticleCategory };
    summary?: SummaryItem[];
    tags?: ArticleTag[];
    asideTopBaner?: AsideBannerComponent;
    asideBottomBaner?: AsideBannerComponent;
    publishedAt?: string;
    createdAt: string;
    updatedAt: string;
    vacanciesAdsSection: VacanciesAdSection;
  };
};

export type VacanciesAdSection = {
  title: string;
  vacanciesAds: VacancyAd[];
};

export type VacanciesAdSectionCards = Pick<VacanciesAdSection, 'title'> & {
  vacancyAdCards: VacancyAdCard[];
};

export type JobFunctionPillar = {
  id: number;
  attributes: {
    title: string;
    slug: string;
    author: { data?: Author };
    pageHero?: HeroComponent;
    body: Array<RichTextCommonComponent>;
    seoMeta?: SeoMetaComponent;
    summary?: SummaryItem[];
    asideTopBaner?: AsideBannerComponent;
    asideBottomBaner?: AsideBannerComponent;
    createdAt: string;
    updatedAt: string;
  };
};

export type VacanciesResultPage = {
  id: number;
  attributes: {
    name: string;
    slug: string;
    type: { id: number; type: string };
    mainContent?: string;
    pageHero?: HeroComponent;
    body: PageBody;
    faq?: FAQItemComponent[];
    seoMeta?: SeoMetaComponent;
    summary?: SummaryItem[];
    asideTopBaner?: AsideBannerComponent;
    asideBottomBaner?: AsideBannerComponent;
    createdAt: string;
    updatedAt: string;
  };
};

export type AboutUsSection = {
  id: number;
  heading: string;
  paragraph: string;
};

export type Author = {
  id: number;
  attributes: {
    firstName: string;
    lastName: string;
    website?: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type ArticleCategory = {
  id: number;
  attributes: {
    title: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type TrendJobsComponent = {
  title: string;
  jobs: TrendJobsItem[];
  showMore?: LinkComponent;
};

export type TrendJobsItem = {
  name: string;
  description: string;
  salary: string;
  href: string;
};

export type StudentsToolsComponent = {
  title: string;
  links: LinkComponent[];
};

export type VideoCTAComponent = {
  title: string;
  description?: string;
  video: EmbeddedYouTube;
  link?: LinkComponent;
};

export type FooterCTAComponent = {
  title: string;
  link?: LinkComponent;
};

export type JobSearchComponent = {
  title: string;
  pageLinks: LinkComponent[];
  showMore?: LinkComponent;
};

export type SeoMetaComponent = {
  title?: string;
  description?: string;
  keywords?: string;
  preventIndexing?: boolean;
  scheme?: Record<string, string>;
  image: { data?: MediaComponent };
};

export type HeroComponent = {
  id: number;
  title?: string;
  subtitle?: string;
  lottieAnimation?: Record<string, string>;
  dateOfPublication?: string;
  readTime?: number;
  isMainHeader: boolean;
  image: { data?: MediaComponent };
};

export type FAQItemComponent = {
  id: number;
  question: string;
  answer: string;
  buildSchemaJson: boolean;
};

export type RichTextCommonComponent = {
  richText: string;
};

export type EmbeddedImageComponent = {
  image: { data?: MediaComponent };
};

export type MediaComponent = {
  id: number;
  attributes: {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: {
      large: MediaFormat;
      small: MediaFormat;
      medium: MediaFormat;
      thumbnail: MediaFormat;
    };
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    provider_metadata: string | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type MediaFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: null;
  size: number;
  width: number;
  height: number;
};

export type SummaryItem = {
  id: number;
  title: string;
  headerText: string;
};

export type ArticleTag = {
  id: number;
  title: string;
};

export type VacancyAd = {
  recommended: boolean;
  importantInfo?: string;
  isExternal: boolean;
  image: { data?: MediaComponent };
  slug?: string;
  companyLogo: { data?: MediaComponent };
  companyName?: string;
  title?: string;
  city?: string;
  shortDescription?: string;
  employmentType?: string;
  salaryMin?: number;
  salaryMax?: number;
  salaryUnit?: string;
  vacancyUrl?: string;
  openInNewWindow: boolean;
  linkText?: string;
};

export type LinkComponent = {
  id: number;
  label: string; // MDText
  href: string;
  isExternal: boolean;
  openNewTab: boolean;
};

export type BannerComponent = {
  id: number;
  title: string;
  description: string;
  lottie?: Record<string, any>;
  link?: LinkComponent;
};

export type AsideBannerComponent = {
  id: number;
  title: string;
  description?: string;
  shareText: string;
  link: LinkComponent;
  requiresAuthentication: boolean;
  lottie?: Record<string, string>;
};

export type UseFullLinksComponent = {
  id: number;
  title: string;
  isNumberedList: boolean;
  items: LinkComponent[];
};

export type FaqComponent = {
  id: number;
  title: string;
  items: FAQItemComponent[];
};

export type PartnersComponent = {
  id: number;
  logos: PartnerLogoComponent[];
};

export type ServicesComponent = {
  id: number;
  titleServices: string;
  subTitle: string;
  items: StatisticsCardComponent[];
  bannerDesktop: { data?: MediaComponent };
  bannerMobile: { data?: MediaComponent };
};

export type QuoteComponent = {
  id: number;
  quoteText: string;
  quoteOrigin: string;
};

export type TestimonialsComponent = {
  id: number;
  testimonialCard: TestimonialCardComponent[];
};

export type TestimonialCardComponent = {
  id: number;
  testimonialQuote: string;
  testimonialName: string;
  testimonialOccupation: string;
  companyLogo: { data?: MediaComponent };
  avatar: { data?: MediaComponent };
};

export type StatisticsCardComponent = {
  id: number;
  Header: string;
  subtext: string;
};

export type PartnerLogoComponent = {
  id: number;
  partnerName: string;
  width: number;
  height: number;
  logo: { data?: MediaComponent };
};

export type InternalLinksComponent = {
  id: number;
  title: string;
  items: LinkComponent[];
  viewMore?: LinkComponent;
};

export type HighlightedLinkComponent = {
  id: number;
  link: LinkComponent;
  description?: string;
};

export type EmbeddedYouTube = {
  id: number;
  title?: string;
  videoId: string;
};

export type CollectionResponse<TCollection> = {
  data: TCollection[];
  meta: {
    pagination: Pagination;
  };
};

export type Pagination = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export type BodyComponent<Name, T> = {
  [x in keyof T]: T[keyof T];
} & {
  id: number;
  __component: Name;
};

export type MaybeNull<T> = T | null;

export type ProductItemComponent = {
  id: number;
  title: string;
  description: string;
  slug: string;
  image: { data?: MediaComponent };
};

export type ProductsLandingPageContent = {
  id: number;
  attributes: {
    slug: string;
    seoMeta?: SeoMetaComponent;
    products: ProductItemComponent[];
    hero: BannerComponent;
    createdAt: string;
    updatedAt: string;
  };
};

export type ProductPage = {
  id: number;
  attributes: {
    title: string;
    slug: string;
    hero: HeroComponent;
    seoMeta?: SeoMetaComponent;
    body: PageBody;
    summary?: SummaryItem[];
    createdAt: string;
    updatedAt: string;
  };
};
