import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { BaseLink } from 'src/common';

export const Container = styled.div`
  margin: 16px 0;

  ${up('md')} {
    margin: 24px 0;
  }

  ul,
  ol {
    padding: 16px 0;

    li:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  ol {
    counter-reset: number;
    margin-left: 2em;
    list-style-type: decimal;

    li {
      padding-left: 4px;
    }

    & li::marker {
      font-size: 1.8rem;
      font-weight: 700;

      color: ${({ theme }) => theme.system.black};
    }
  }
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.4;

  color: ${({ theme }) => theme.system.black};
`;

export const StyledLink = styled(BaseLink)`
  font-size: 1.8rem;
  line-height: 1.4;

  color: ${({ theme }) => theme.brand.orange};

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    color: ${({ theme }) => theme.brand.darkOrange};
  }

  &:visited {
    color: ${({ theme }) => theme.brand.darkOrange};
  }

  &::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjRUY3RDAwIiBkPSJNMTAgNnYySDV2MTFoMTF2LTVoMnY2YTEuMDAwMSAxLjAwMDEgMCAwIDEtMSAxSDRhMS4wMDAyIDEuMDAwMiAwIDAgMS0xLTFWN2ExIDEgMCAwIDEgMS0xaDZabTExLTN2OGgtMlY2LjQxM2wtNy43OTMgNy43OTQtMS40MTQtMS40MTRMMTcuNTg1IDVIMTNWM2g4WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 8px;
  }

  strong {
    font-weight: 700;
  }
`;
