import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  width: 100%;
  margin: 16px 0;
  // left padding - border width
  padding: 12px 16px 12px 12px;

  border-left: 4px solid ${({ theme }) => theme.brand.orange};
  background-color: ${({ theme }) => theme.brand.lightOrange};

  ${up('md')} {
    margin: 24px 0;
  }

  a {
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 1.4;
    display: inline-block;
    color: ${({ theme }) => theme.brand.orange};

    & > * {
      display: inline-block;
    }

    &::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjRUY3RDAwIiBkPSJNMTAgNnYySDV2MTFoMTF2LTVoMnY2YTEuMDAwMSAxLjAwMDEgMCAwIDEtMSAxSDRhMS4wMDAyIDEuMDAwMiAwIDAgMS0xLTFWN2ExIDEgMCAwIDEgMS0xaDZabTExLTN2OGgtMlY2LjQxM2wtNy43OTMgNy43OTQtMS40MTQtMS40MTRMMTcuNTg1IDVIMTNWM2g4WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 8px;
    }
  }
`;

export const CaptionText = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
`;
