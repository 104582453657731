import { memo, FC } from 'react';
import Image from 'next/image';

import { Blur, Button, Wrapper, Description, TextContent, Title } from './BannerBusiness.styled';
import { LinkComponent } from '../../../api_entities/strapi/types';

export type BannerBusinessProps = {
  title: string;
  description: string;
  link?: LinkComponent;
};

const BannerBusiness: FC<BannerBusinessProps> = ({ title, description, link }) => {
  return (
    <Wrapper>
      <Blur />
      <TextContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContent>
      {link ? (
        <Button
          href={link.href}
          rel={link.isExternal ? 'noopener' : undefined}
          target={link.openNewTab ? '_blank' : undefined}
        >
          {link.label}
          <Image src="/assets/rightWhite.svg" alt="Arrow" width={24} height={24} />
        </Button>
      ) : null}
    </Wrapper>
  );
};

export default memo(BannerBusiness);
