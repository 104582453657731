import { FC, memo } from 'react';

import { LinkComponent } from 'api_entities/strapi/types';
import { RichText } from 'src/components/RichText';
import { richtextOptions } from './richTextOptions';

import { Container, Title, StyledLink } from './UsefulLinks.styles';

export type UsefulLinksProps = {
  title: string;
  isNumberedList?: boolean;
  items: LinkComponent[];
};

const UsefulLinks: FC<UsefulLinksProps> = ({ title, items, isNumberedList }) => {
  const listItems = items.map(({ id, href, label, isExternal, openNewTab }) => (
    <li key={id}>
      <StyledLink passHref href={href} isExternal={isExternal} openNewTab={openNewTab}>
        <RichText options={richtextOptions}>{label}</RichText>
      </StyledLink>
    </li>
  ));

  return (
    <Container>
      <Title>{title}</Title>
      {isNumberedList ? <ol>{listItems}</ol> : <ul>{listItems}</ul>}
    </Container>
  );
};

export default memo(UsefulLinks);
