import React, { FC, memo } from 'react';
import { Icons } from '@studenten/ui-components';

import { LinkComponent } from 'api_entities/strapi/types';

import { Container, Title, List, StyledLink, StyledListItemLink } from './InternalLinks.styles';

export type InternalLinksProps = {
  title: string;
  items: LinkComponent[];
  viewMore?: LinkComponent;
};

const InternalLinks: FC<InternalLinksProps> = ({ title, items, viewMore }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {items.map(({ id, href, label, isExternal, openNewTab }) => (
          <li key={id}>
            <StyledListItemLink
              passHref
              href={href}
              isExternal={isExternal}
              openNewTab={openNewTab}
            >
              <span>{label}</span>
              <Icons.ChevronRight width="24" height="24" />
            </StyledListItemLink>
          </li>
        ))}
      </List>
      {viewMore ? (
        <div>
          <StyledLink
            passHref
            href={viewMore.href}
            isExternal={viewMore.isExternal}
            openNewTab={viewMore.openNewTab}
          >
            {viewMore.label}
          </StyledLink>
        </div>
      ) : null}
    </Container>
  );
};

export default memo(InternalLinks);
