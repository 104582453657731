import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { BaseLink } from 'src/common';

export const Container = styled.div`
  margin: 16px 0;

  ${up('md')} {
    margin: 24px 0;
  }
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.4;

  color: ${({ theme }) => theme.system.black};
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 32px -16px 32px;

  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;

  li {
    margin: 0 8px 8px;
    flex: 1 0 calc(100% - 16px);

    ${up('md')} {
      flex: 0 1 calc(50% - 16px);
    }
  }

  ${up('md')} {
    margin: 24px -16px 24px;
  }
`;

export const StyledListItemLink = styled(BaseLink)`
  position: relative;
  width: 100%;
  display: block;
  padding: 14px 48px 14px 16px;
  border-radius: 8px;

  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  background-color: ${({ theme }) => theme.system.white};
  color: ${({ theme }) => theme.system.black};

  span {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.system.darkGreyActive};
  }

  &:visited {
    color: ${({ theme }) => theme.system.black};
  }
`;

export const StyledLink = styled(BaseLink)`
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4;

  color: ${({ theme }) => theme.brand.orange};

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    color: ${({ theme }) => theme.brand.darkOrange};
  }

  &:visited {
    color: ${({ theme }) => theme.brand.darkOrange};
  }

  &::after {
    position: absolute;
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjRUY3RDAwIiBkPSJNLjkwOSA5LjU0NTRoMTUuOTg3MkwxMy45MDI2IDYuNTUyYS45MDkxLjkwOTEgMCAwIDEgMS4yODU3LTEuMjg1N2w0LjU0NTQgNC41NDU1YS45MDkuOTA5IDAgMCAxIDAgMS4yODU2bC00LjU0NTQgNC41NDU1YS45MDY2LjkwNjYgMCAwIDEtLjY0MjkuMjY2My45MDkyLjkwOTIgMCAwIDEtLjY0MjgtMS41NTJsMi45OTM2LTIuOTkzNUguOTA5MWEuOTA5LjkwOSAwIDAgMSAwLTEuODE4MloiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjAgMEgwdjIwaDIweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==);
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
