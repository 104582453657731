import * as React from 'react';
import { BODY_COMPONENTS, PageBody } from 'api_entities/strapi/types';
import { RichText } from 'src/components/RichText';
import { FAQ } from 'src/components/FAQ';
import { Banner } from 'src/components/Banner';
import { UsefulLinks } from 'src/components/UsefulLinks';
import { InternalLinks } from 'src/components/InternalLinks';
import { EmbeddedVideo } from 'src/components/EmbeddedVideo';
import { HighlightedLink } from 'src/components/HighlightedLink';
import { richtextOptionsBusiness } from '../RichText/Richtext.options';
import { BannerBusiness } from '../BannerBusiness';

export const getBodyComponents = (strapiComponents: PageBody) => {
  const components = strapiComponents.map((comp) => {
    switch (comp.__component) {
      case BODY_COMPONENTS.RICH_TEXT: {
        return <RichText key={comp.id + comp.__component}>{comp.richText}</RichText>;
      }

      case BODY_COMPONENTS.FAQ: {
        const accordionData = comp.items.map(
          ({ question: header, answer: text, buildSchemaJson }) => ({
            header,
            text,
            buildSchemaJson,
          })
        );

        return (
          <FAQ
            embedded
            key={comp.id + comp.__component}
            title={comp.title}
            accordionData={accordionData}
          />
        );
      }

      case BODY_COMPONENTS.USEFULL_LINKS: {
        return (
          <UsefulLinks
            key={comp.id + comp.__component}
            title={comp.title}
            items={comp.items}
            isNumberedList={comp.isNumberedList}
          />
        );
      }

      case BODY_COMPONENTS.INTERNAL_LINKS: {
        return (
          <InternalLinks
            key={comp.id + comp.__component}
            title={comp.title}
            items={comp.items}
            viewMore={comp.viewMore}
          />
        );
      }

      case BODY_COMPONENTS.BANNER: {
        return (
          <Banner
            key={comp.id + comp.__component}
            title={comp.title}
            description={comp.description}
            link={comp.link}
            lottie={comp.lottie}
          />
        );
      }

      case BODY_COMPONENTS.EMBEDDED_YOUTUBE: {
        return (
          <EmbeddedVideo
            key={comp.id + comp.__component}
            title={comp.title}
            ytVideoId={comp.videoId}
          />
        );
      }

      case BODY_COMPONENTS.HIGHLIGHTED_LINK: {
        return (
          <HighlightedLink
            key={comp.id + comp.__component}
            link={comp.link}
            description={comp.description}
          />
        );
      }

      default: {
        return null;
      }
    }
  });

  return React.Children.toArray(components);
};

export const getBodyComponentsBusiness = (strapiComponents: PageBody) => {
  const components = strapiComponents.map((comp) => {
    switch (comp.__component) {
      case BODY_COMPONENTS.RICH_TEXT: {
        return (
          <RichText options={richtextOptionsBusiness} key={comp.id + comp.__component}>
            {comp.richText}
          </RichText>
        );
      }

      case BODY_COMPONENTS.FAQ: {
        const accordionData = comp.items.map(
          ({ question: header, answer: text, buildSchemaJson }) => ({
            header,
            text,
            buildSchemaJson,
          })
        );

        return (
          <FAQ
            embedded
            key={comp.id + comp.__component}
            title={comp.title}
            accordionData={accordionData}
          />
        );
      }

      case BODY_COMPONENTS.BANNER: {
        return (
          <BannerBusiness
            key={comp.id + comp.__component}
            title={comp.title}
            description={comp.description}
            link={comp.link}
          />
        );
      }

      default: {
        return null;
      }
    }
  });

  return React.Children.toArray(components);
};
