import { FC, memo } from 'react';

import { LinkComponent } from 'api_entities/strapi/types';
import { BaseLink } from 'src/common';
import { RichText } from 'src/components/RichText';

import richTextOptions from './richtextOptions';

import { Container, CaptionText } from './Highlighted.styles';

export type HighlightedLinkProps = {
  link: LinkComponent;
  description?: string;
};

const HighlightedLink: FC<HighlightedLinkProps> = ({ link, description }) => {
  return (
    <Container>
      <BaseLink href={link.href} isExternal={link.isExternal} openNewTab={link.openNewTab}>
        <RichText options={richTextOptions}>{link.label}</RichText>
      </BaseLink>
      {description ? <CaptionText>{description}</CaptionText> : null}
    </Container>
  );
};

export default memo(HighlightedLink);
